import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: localStorage.getItem('easycoAppAnggota') != null ? JSON.parse(localStorage.getItem('easycoAppAnggota')) : [],
  },
  getters: {
    user: state => state.user,
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload
      localStorage.setItem('easycoAppAnggota', JSON.stringify(state.user))
    },
    removeUser(state) {
      state.user = []
      localStorage.removeItem('easycoAppAnggota')
    }
  },
  actions: {
    login({ commit }, payload) {
      commit('setUser', payload)
    },
    logout({ commit }) {
      commit('removeUser')
    }
  },
  modules: {
  }
})
